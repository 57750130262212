'use client'

import { Message } from '@locmod/intl'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { constants } from 'helpers'

import err404Image from 'public/images/error-404.png'
import bgImage from 'public/images/error-bg.png'

import { Button, buttonMessages } from 'components/inputs'

import messages from './messages'


const Content: React.FC = () => {
  const pathname = usePathname()
  const isApp = pathname.startsWith('/app')

  return (
    <div className="w-full bg-bg-10">
      <div className="relative -sm:min-h-[29rem] sm:min-h-[36rem] flex items-center justify-center">
        <Image
          className="absolute top-0 left-0 z-0 size-full object-center object-cover"
          src={bgImage}
          alt=""
          draggable={false}
        />
        <div className="text-center z-0 px-6">
          <Image
            className="w-auto h-[6.25rem] mx-auto"
            src={err404Image}
            alt="Error 404"
          />
          <Message
            className="mt-6 text-grey-80"
            value={messages.title}
            tag="h1"
          />
          <Button
            className="mt-6"
            title={buttonMessages.goHome}
            to={isApp ? constants.links.app : '/'}
            styling="primary"
            rightIcon="interface/chevron-right"
            size={44}
          />
        </div>
      </div>
    </div>
  )
}

export default Content
